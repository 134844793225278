import React, {
  createContext,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { motion, MotionConfig } from "framer-motion";
import { Link } from "react-router-dom";
import { XMarkIcon, Bars2Icon } from "@heroicons/react/24/outline";
// Update other imports as needed
import { Button } from "./components/Button";
import { Container } from "./components/Container";
import { Footer } from "./components/Footer";
import { GridPattern } from "./components/GridPattern";
// import { Logo, Logomark } from './Logo';
import { Offices } from "./components/Offices";
import { SocialMedia } from "./components/SocialMedia";

const RootLayoutContext = createContext<{
  logoHovered: boolean;
  setLogoHovered: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

type ChildrenProps = {
  children: React.ReactNode;
};

type HeaderProps = {
  panelId: string;
  icon: React.ComponentType<{ className?: string }>;
  expanded: boolean;
  onToggle: () => void;
  toggleRef: React.RefObject<HTMLButtonElement>;
  invert?: boolean;
};

function Header({
  panelId,
  icon: Icon,
  expanded,
  onToggle,
  toggleRef,
  invert = false,
}: HeaderProps) {
  const { setLogoHovered } = useContext(RootLayoutContext)!;

  return (
    <Container>
      <div className="flex items-center justify-between">
        <Link
          to="/"
          aria-label="Home"
          onMouseEnter={() => setLogoHovered(true)}
          onMouseLeave={() => setLogoHovered(false)}>
          <div className="font-display text-lg font-semibold tracking-wider text-dark-brown ">
            Innova Dental
          </div>
          {/* <Logomark
            className="h-8 sm:hidden"
            invert={invert}
            filled={logoHovered}
          />
          <Logo
            className="hidden h-8 sm:block"
            invert={invert}
            filled={logoHovered}
          /> */}
        </Link>
        <div className="flex items-center gap-x-8">
          <Button href="/contact" invert={invert}>
            Contact us
          </Button>
          <button
            ref={toggleRef}
            type="button"
            onClick={onToggle}
            aria-expanded={expanded ? "true" : "false"}
            aria-controls={panelId}
            className={clsx(
              "group -m-2.5 rounded-full p-2.5 transition",
              invert ? "hover:bg-light-brown" : "hover:bg-light-brown"
            )}
            aria-label="Toggle navigation">
            <Icon
              className={clsx(
                "h-6 w-6",
                invert
                  ? "fill-light-beige text-light-beige group-hover:fill-neutral-200"
                  : "fill-neutral-950 group-hover:fill-neutral-700"
              )}
            />
          </button>
        </div>
      </div>
    </Container>
  );
}

function NavigationRow({ children }: ChildrenProps) {
  return (
    <div className="even:mt-px sm:bg-neutral-950">
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2">{children}</div>
      </Container>
    </div>
  );
}

type NavigationItemProps = {
  href: string;
  children: React.ReactNode;
};

function NavigationItem({ href, children }: NavigationItemProps) {
  return (
    <div>
      <a href={href}>{children}</a>
    </div>
  );
}

function Navigation() {
  return (
    <nav className="mt-px font-display text-5xl font-medium tracking-tight text-light-beige">
      <NavigationRow>
        <NavigationItem href="/work">Puna Jone</NavigationItem>
        <NavigationItem href="/about">Rreth Nesh</NavigationItem>
      </NavigationRow>
    
    </nav>
  );
}

function RootLayoutInner({ children }: ChildrenProps) {
  let panelId = useId();
  let [expanded, setExpanded] = useState(false);
  let openRef = useRef<HTMLButtonElement>(null);
  let closeRef = useRef<HTMLButtonElement>(null);
  let navRef = useRef<HTMLDivElement>(null);
  let shouldReduceMotion = false; // Replace this with the appropriate value for useReducedMotion()

  useEffect(() => {
    function onClick(event: MouseEvent) {
      if (
        event.target instanceof HTMLElement &&
        event.target.closest("a")?.href === window.location.href
      ) {
        setExpanded(false);
      }
    }

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <MotionConfig transition={shouldReduceMotion ? { duration: 0 } : undefined}>
      <header>
        <div
          className="absolute left-0 right-0 top-2 z-40 pt-14"
          aria-hidden={expanded ? "true" : undefined}>
          <Header
            panelId={panelId}
            icon={Bars2Icon}
            toggleRef={openRef}
            expanded={expanded}
            onToggle={() => {
              setExpanded((expanded) => !expanded);
              window.setTimeout(() => closeRef.current?.focus());
            }}
          />
        </div>

        <motion.div
          layout
          id={panelId}
          style={{ height: expanded ? "auto" : "0.5rem" }}
          className="relative z-50 overflow-hidden bg-neutral-950 pt-2"
          aria-hidden={expanded ? undefined : "true"}>
          <motion.div layout className="bg-dark-brown">
            <div ref={navRef} className="bg-neutral-950 pb-16 pt-14">
              <Header
                invert
                panelId={panelId}
                icon={XMarkIcon}
                toggleRef={closeRef}
                expanded={expanded}
                onToggle={() => {
                  setExpanded((expanded) => !expanded);
                  window.setTimeout(() => openRef.current?.focus());
                }}
              />
            </div>
            <Navigation />
            <div className="relative bg-neutral-950">
              <Container>
                <div className="grid grid-cols-1 gap-y-10 pb-16 pt-10 sm:grid-cols-2 sm:pt-16">
                  <div>
                    <h2 className="font-display text-base font-semibold text-light-beige">
                      Ku ndodhemi?
                    </h2>
                    <Offices
                      invert
                      className="mt-6 grid text-white grid-cols-1 gap-8 sm:grid-cols-2"
                    />
                  </div>
                  <div className="sm:border-l sm:border-light-beige sm:pl-16">
                    <h2 className="font-display text-base font-semibold text-light-beige">
                      Na ndiqni ne:
                    </h2>
                    <SocialMedia className="mt-6" invert />
                  </div>
                </div>
              </Container>
            </div>
          </motion.div>
        </motion.div>
      </header>

      <motion.div
        layout
        style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
        className="relative flex flex-auto overflow-hidden bg-baseCream pt-14">
        <motion.div
          layout
          className="relative isolate flex w-full flex-col pt-9">
          <GridPattern className="absolute inset-x-0 -top-14 -z-10 h-[1000px] w-full fill-light-base stroke-neutral-950/5 [mask-image:linear-gradient(to_bottom_left,white_40%,transparent_50%)]" />

          <main className="w-full flex-auto">{children}</main>

          <Footer />
        </motion.div>
      </motion.div>
    </MotionConfig>
  );
}

export function RootLayout({ children }: ChildrenProps) {
  let [logoHovered, setLogoHovered] = useState(false);

  return (
    <RootLayoutContext.Provider value={{ logoHovered, setLogoHovered }}>
      <RootLayoutInner>{children}</RootLayoutInner>
    </RootLayoutContext.Provider>
  );
}
