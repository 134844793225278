import { PageIntro } from "../components/PageIntro"
import { ContactSection } from "../components/ContactSession"
import { Testimonial } from "../components/Testimonial"
 function Work() {
    
    // let caseStudies = await loadCaseStudies()
    return (
      <>
        <PageIntro
          eyebrow="Our work"
          title="Proven solutions for real-world problems."
        >
          <p>
            We believe in efficiency and maximizing our resources to provide the
            best value to our clients. The primary way we do that is by re-using
            the same five projects we’ve been developing for the past decade.
          </p>
        </PageIntro>
  
        {/* <CaseStudies caseStudies={caseStudies} /> */}
  
        <Testimonial
          className="mt-24 sm:mt-32 lg:mt-40"
          client={{ name: '', logo: '' }}
        >
          We approached <em>Studio</em> because we loved their past work. They
          delivered something remarkably similar in record time.
        </Testimonial>
  
  
        <ContactSection />
      </>
    )
  }
  export default Work