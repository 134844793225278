import clsx from "clsx";

function Office({
  name,
  children,
  invert = false,
}: {
  name: string;
  children: React.ReactNode;
  invert?: boolean;
}) {
  return (
    <address className={clsx("text-sm not-italic")}>
      <strong>{name}</strong>
      <br />
      {children}
    </address>
  );
}

export function Offices({
  invert = false,
  ...props
}: React.ComponentPropsWithoutRef<"ul"> & { invert?: boolean }) {
  return (
    <ul {...props}>
      <li>
        <Office name="Tirane" invert={invert}>
        Blv “Bajram Curri” , Ura e Nisharakut
          <br />
         ish stacioni i fundit i Tiranes se Re
        </Office>
      </li>
     
    </ul>
  );
}
